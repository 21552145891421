<template>
  <div class="ui grid container-1" id="hhlpcntr">
    <div class="two column row stackable">
      <div class="column">
        <h3>WE ARE HERE</h3>
        <h1>TO HELP</h1>
        <p>You have a community behind you, ready to answer your queries and hear your feedback.</p>
      </div>
      <div class="column">
        <div class="image-div">
          <img class="people" src="@/assets/hd1.png">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'content1'
}
</script>

<style scoped lang='scss'>
</style>
